import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'

import { TGetCommunicationsResponseData } from './ServiceTypes/getCommunications.ServiceTypes'
import { TGetSendMetricsResponseData } from './ServiceTypes/getSendMetrics.ServiceTypes'

// export const sampleAction = createAction('Communication/sample')

export const getCommunicationsServiceName = 'Communication/getCommunications'
export const getCommunicationsTraceActions =
  traceActionsCreator<TGetCommunicationsResponseData>(
    getCommunicationsServiceName
  )

export const resetCommunicationReducerAction = createAction(
  'Communication/resetReducer'
)

export const getSendMetricsServiceName = 'Communication/getSendMetrics'
export const getSendMetricsTraceActions =
  traceActionsCreator<TGetSendMetricsResponseData>(getSendMetricsServiceName)
