import { combineReducers, ReducersMapObject } from 'redux'

import AddressReducer from './Address/Reducer'
import { SLICE_NAME as AddressSliceName } from './Address/Selectors'
import { TAddressReducer } from './Address/TYPES'
import AuthReducer from './Auth/Reducer'
import { SLICE_NAME as AuthSliceName } from './Auth/Selectors'
import { TAuthReducer } from './Auth/TYPES'
import CommunicationReducer from './Communication/Reducer'
import { SLICE_NAME as CommunicationSliceName } from './Communication/Selectors'
import { TCommunicationReducer } from './Communication/TYPES'
import CommunicationTemplateReducer from './CommunicationTemplate/Reducer'
import { SLICE_NAME as CommunicationTemplateSliceName } from './CommunicationTemplate/Selectors'
import { TCommunicationTemplateReducer } from './CommunicationTemplate/TYPES'
import LeadReducer from './Lead/Reducer'
import { SLICE_NAME as LeadSliceName } from './Lead/Selectors'
import { TLeadReducer } from './Lead/TYPES'
import LeadFiltersReducer from './LeadFilters/Reducer'
import { SLICE_NAME as LeadFiltersSliceName } from './LeadFilters/Selectors'
import { TLeadFiltersReducer } from './LeadFilters/TYPES'
import QueueReducer from './Queue/Reducer'
import { SLICE_NAME as QueueSliceName } from './Queue/Selectors'
import { TQueueReducer } from './Queue/TYPES'
import ThemeReducer from './Theme/Reducer'
import { SLICE_NAME as ThemeSliceName } from './Theme/Selectors'
import { TThemeReducer } from './Theme/TYPES'
import UserReducer from './User/Reducer'
import { SLICE_NAME as UserSliceName } from './User/Selectors'
import { TUserReducer } from './User/TYPES'

import ServiceTrackerReducer from './ServiceTracker/Reducer'
import { SLICE_NAME as ServiceTrackerSliceName } from './ServiceTracker/Selectors'
import { TServiceTrackerReducer } from './ServiceTracker/TYPES'

export type TReducers = {
  [AuthSliceName]: TAuthReducer
  [ServiceTrackerSliceName]: TServiceTrackerReducer
  [ThemeSliceName]: TThemeReducer
  [LeadSliceName]: TLeadReducer
  [LeadFiltersSliceName]: TLeadFiltersReducer
  [CommunicationTemplateSliceName]: TCommunicationTemplateReducer
  [AddressSliceName]: TAddressReducer
  [CommunicationSliceName]: TCommunicationReducer
  [UserSliceName]: TUserReducer
  [QueueSliceName]: TQueueReducer
}

const reducers: ReducersMapObject<TReducers> = {
  [AuthSliceName]: AuthReducer,
  [ServiceTrackerSliceName]: ServiceTrackerReducer,
  [ThemeSliceName]: ThemeReducer,
  [LeadSliceName]: LeadReducer,
  [LeadFiltersSliceName]: LeadFiltersReducer,
  [CommunicationTemplateSliceName]: CommunicationTemplateReducer,
  [AddressSliceName]: AddressReducer,
  [CommunicationSliceName]: CommunicationReducer,
  [UserSliceName]: UserReducer,
  [QueueSliceName]: QueueReducer
}

export default combineReducers<ReducersMapObject<TReducers>>(reducers)

export const persistedReducers: (keyof TReducers)[] = [
  AuthSliceName,
  UserSliceName
]
