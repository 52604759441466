import React, { useEffect } from 'react'
import {
  isRouteErrorResponse,
  useNavigate,
  useRouteError
} from 'react-router-dom'

import Loader from '~/src/Components/Loader'

export default function ErrorBoundary() {
  const error = useRouteError()
  const navigate = useNavigate()

  useEffect(() => {
    if (isRouteErrorResponse(error)) {
      return navigate(error.data)
    }
  })

  return <Loader />
}
