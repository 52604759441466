import { T_COMMUNICATION_TEMPLATE } from '~/src/Entities/CommunicationTemplate/CommunicationTemplate.Types'

export type TCommunicationTemplateReducer = {
  communicationTemplates: T_COMMUNICATION_TEMPLATE[]
  selectedCommunicationTemplate?: T_COMMUNICATION_TEMPLATE
}

export const INITIAL_STATE: TCommunicationTemplateReducer = {
  communicationTemplates: []
}
