import React, { PureComponent } from 'react'

import Loader, { ILoaderProps } from './Loader'

export interface ILoaderFullPageProps extends Omit<ILoaderProps, 'ref'> {}

export default class LoaderFullPage extends PureComponent<ILoaderFullPageProps> {
  render() {
    return (
      <Loader
        {...this.props}
        sx={{
          position: 'fixed',
          top: '0',
          left: '0',
          height: '100%',
          width: '100%',
          zIndex: 1600,
          ...this.props.sx
        }}
      />
    )
  }
}
