import {
  TLeadClassificationLabel,
  TLeadClassificationMap,
  TLeadLabel,
  TLeadSourceLabel,
  TLeadSourceMap,
  TLeadStatusLabel,
  TLeadStatusMap,
  TLeadTypeLabel,
  TLeadTypeMap
} from './Lead.Types'

export const LEAD_TYPE: TLeadTypeMap = {
  B2B: 'B2B',
  B2C: 'B2C'
}

export const LEAD_TYPE_LABEL: TLeadTypeLabel = {
  B2B: 'B2B',
  B2C: 'B2C'
}

export const LEAD_SOURCE: TLeadSourceMap = {
  ORGANIC: 'ORGANIC',
  EMAILLIST_SHOP: 'EMAILLIST_SHOP'
}

export const LEAD_SOURCE_LABEL: TLeadSourceLabel = {
  ORGANIC: 'Organic',
  EMAILLIST_SHOP: 'Emaillist.Shop'
}

export const LEAD_CLASSIFICATION: TLeadClassificationMap = {
  INBOUND: 'INBOUND',
  OUTBOUND: 'OUTBOUND'
}

export const LEAD_CLASSIFICATION_LABEL: TLeadClassificationLabel = {
  INBOUND: 'Inbound',
  OUTBOUND: 'Outbound'
}

export const LEAD_STATUS: TLeadStatusMap = {
  UNKNOWN: 'UNKNOWN',
  VALID: 'VALID',
  INVALID: 'INVALID',
  HARD_BOUNCE: 'HARD_BOUNCE',
  SOFT_BOUNCE: 'SOFT_BOUNCE',
  OPT_OUT: 'OPT_OUT'
}

export const LEAD_STATUS_LABEL: TLeadStatusLabel = {
  UNKNOWN: 'Unknown',
  VALID: 'Valid',
  INVALID: 'Invalid',
  HARD_BOUNCE: 'Hard Bounce',
  SOFT_BOUNCE: 'Soft Bounce',
  OPT_OUT: 'Opt Out'
}

export const LEAD_LABEL: TLeadLabel = {
  id: 'Lead ID',
  classification: 'Lead Classification',
  type: 'Lead Type',
  source: 'Lead Source',
  companyName: 'Company Name',
  name: 'Name',
  email: 'Email',
  sicCode: 'SIC Code',
  sicDescription: 'SIC Description',
  webAddress: 'Web Address',
  address: 'Address',
  city: 'City',
  state: 'State',
  country: 'Country',
  zipCode: 'Zip Code',
  phoneNo: 'Phone No.',
  mobileNo: 'Mobile No.',
  faxNo: 'Fax No.',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  successCommunications: 'Success Comm.',
  failedCommunications: 'Failed Comm.',
  status: 'Lead Status'
}
