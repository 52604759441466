import { createSelector } from '@reduxjs/toolkit'

import { TAppSore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'communicationTemplate'

const select = (state: TAppSore) => state[SLICE_NAME]

export const getCommunicationTemplateReducer = select

export const getCommunicationTemplatesSelector = createSelector(
  select,
  reducer => reducer.communicationTemplates
)

export const getSelectedCommunicationTemplatesSelector = createSelector(
  select,
  reducer => reducer.selectedCommunicationTemplate
)
