import { createSelector } from '@reduxjs/toolkit'

import { TAppSore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'lead'

const select = (state: TAppSore) => state[SLICE_NAME]

export const getLeadReducer = select

export const getLeadSearchParamsSelector = createSelector(
  select,
  reducer => reducer.searchParams
)

export const getLeadSearchResultSelector = createSelector(
  select,
  reducer => reducer.searchResult
)
