import React, { Component } from 'react'
import { DsBox, DsTypography } from '@am92/react-design-system'

interface IHeaderProps {}

class Header extends Component<IHeaderProps> {
  render() {
    return (
      <DsBox
        sx={theme => ({
          px: {
            xs: 'var(--ds-spacing-bitterCold)',
            lg: 'var(--ds-spacing-pleasant)'
          },
          py: 'var(--ds-spacing-cool)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar,
          borderBottom: '1px solid var(--ds-colour-strokeDefault)'
        })}
      >
        <DsTypography variant='headingBoldExtraLarge'>Inque</DsTypography>
      </DsBox>
    )
  }
}

export default Header
