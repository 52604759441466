import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import { listAddressesTraceActions } from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, TAddressReducer } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TAddressReducer>): void => {
    builder.addCase(listAddressesTraceActions.success, (state, { payload }) => {
      state.addresses = payload
    })
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
