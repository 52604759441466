import { TQueueStatus } from '~/src/Entities/Queue/Queue.Types'

export type TQueueReducer = {
  communication: TQueueStatus
  sanitize: TQueueStatus
}

export const INITIAL_STATE: TQueueReducer = {
  communication: {
    isProcessing: false,
    approximateNumberOfMessages: 0
  },
  sanitize: {
    isProcessing: false,
    approximateNumberOfMessages: 0
  }
}
