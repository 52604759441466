import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'

import { TCreateCommunicationTemplateResponseData } from './ServiceTypes/createCommunicationTemplate.ServiceTypes'
import { TFindCommunicationTemplateByIdResponseData } from './ServiceTypes/findCommunicationTemplateById.ServiceTypes'
import { TGetCommunicationTemplatesByStatusResponseData } from './ServiceTypes/getCommunicationTemplatesByStatus.ServiceTypes'
import { TListCommunicationTemplatesResponseData } from './ServiceTypes/listCommunicationTemplates.Service'

export const createCommunicationTemplateServiceName =
  'CommunicationTemplate/createCommunicationTemplate'
export const createCommunicationTemplateTraceActions =
  traceActionsCreator<TCreateCommunicationTemplateResponseData>(
    createCommunicationTemplateServiceName
  )

export const listCommunicationTemplatesServiceName =
  'CommunicationTemplate/listCommunicationTemplates'
export const listCommunicationTemplatesTraceActions =
  traceActionsCreator<TListCommunicationTemplatesResponseData>(
    listCommunicationTemplatesServiceName
  )

export const findCommunicationTemplateByIdServiceName =
  'CommunicationTemplate/findCommunicationTemplateById'
export const findCommunicationTemplateByIdTraceActions =
  traceActionsCreator<TFindCommunicationTemplateByIdResponseData>(
    findCommunicationTemplateByIdServiceName
  )

export const resetSelectedCommunicationTemplateAction = createAction(
  'CommunicationTemplate/resetSelectedCommunicationTemplate'
)

export const resetCommunicationTemplateReducerAction = createAction(
  'CommunicationTemplate/resetReducer'
)

export const getCommunicationTemplatesByStatusServiceName =
  'CommunicationTemplate/getCommunicationTemplatesByStatus'
export const getCommunicationTemplatesByStatusTraceActions =
  traceActionsCreator<TGetCommunicationTemplatesByStatusResponseData>(
    getCommunicationTemplatesByStatusServiceName
  )
