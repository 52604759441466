import { TGetSendMetricsResponseData } from './ServiceTypes/getSendMetrics.ServiceTypes'

import { T_COMMUNICATION } from '~/src/Entities/Communication/Communication.Types'

export type TCommunicationReducer = {
  communications: T_COMMUNICATION[]
  sendMetrics: TGetSendMetricsResponseData
}

export const INITIAL_STATE: TCommunicationReducer = {
  communications: [],
  sendMetrics: {
    total: 0,
    distributionType: 'deliveryStatus',
    distribution: {
      DUPLICATE: 0,
      OPT_OUT: 0,
      PERMANENT_FAILURE: 0,
      SUCCESSFUL: 0,
      TEMPORARY_FAILURE: 0,
      THROTTLED: 0,
      TIMEOUT: 0,
      UNKNOWN_FAILURE: 0
    }
  }
}
