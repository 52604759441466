import { createAction } from '@reduxjs/toolkit'

import traceActionsCreator from '../traceActionsCreator'
import { TLeadSearchParams } from './TYPES'

import { TSearchLeadResponseData } from './ServiceTypes/searchLead.ServiceTypes'
import { TSendCommunicationResponseData } from './ServiceTypes/sendCommunication.ServiceTypes'

export const setSearchParamsAction = createAction<TLeadSearchParams>(
  'Lead/setSearchParams'
)

export const resetSearchParamsAction = createAction('Lead/resetSearchParams')

export const searchLeadServiceName = 'Lead/searchLead'
export const searchLeadTraceActions =
  traceActionsCreator<TSearchLeadResponseData>(searchLeadServiceName)

export const resetLeadReducerAction = createAction('Lead/resetReducer')

export const sendCommunicationServiceName = 'Lead/sendCommunication'
export const sendCommunicationTraceActions =
  traceActionsCreator<TSendCommunicationResponseData>(
    sendCommunicationServiceName
  )
