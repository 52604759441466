import { T_USER } from '~/src/Entities/User/User.Types'

export type TUserReducer = T_USER

export const INITIAL_STATE: TUserReducer = {
  id: '',
  firstName: '',
  lastName: '',
  fullName: '',
  initials: '',
  emailId: '',
  userType: 'ADMIN'
}
