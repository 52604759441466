import { createSelector } from '@reduxjs/toolkit'

import { TAppSore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'leadFilters'

const select = (state: TAppSore) => state[SLICE_NAME]

export const getLeadFiltersReducer = select

export const getLeadSicFiltersSelector = createSelector(
  select,
  reducer => reducer.sicFilters
)

export const getLeadCountryFiltersSelector = createSelector(
  select,
  reducer => reducer.countryFilters
)

export const getLeadStateFiltersSelector = createSelector(
  select,
  reducer => reducer.stateFilters
)

export const getLeadCityFiltersSelector = createSelector(
  select,
  reducer => reducer.cityFilters
)
