import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  // HOME: {
  //   pathname: ''
  // } as AppRouteItem,

  LOGIN: {
    pathname: '/auth/login'
  } as AppRouteItem,

  DASHBOARD: {
    pathname: '/dashboard'
  } as AppRouteItem,

  COMMUNICATION_TEMPLATE_LIST: {
    pathname: '/communication-templates'
  } as AppRouteItem,

  COMMUNICATION_TEMPLATE_FORM: {
    pathname: '/communication-templates/:id'
  } as AppRouteItem,

  LEAD_DASHBOARD: {
    pathname: '/leads/dashboard'
  } as AppRouteItem,

  LEAD_LIST: {
    pathname: '/leads/list'
  } as AppRouteItem,

  LEAD_COMMUNICATION: {
    pathname: '/leads/communication'
  } as AppRouteItem,

  LEAD_SANITIZATION: {
    pathname: '/leads/sanitization'
  } as AppRouteItem,

  LEAD_ANALYTICS: {
    pathname: '/leads/analytics'
  } as AppRouteItem,

  ANY: {
    pathname: '*'
  } as AppRouteItem,

  DEFAULT_UNAUTH_FALLBACK: {
    pathname: '/auth/login'
  } as AppRouteItem,

  // TODO: Set to Login Page
  DEFAULT_AUTH_FALLBACK: {
    pathname: '/dashboard'
  } as AppRouteItem
}

export default APP_ROUTES
