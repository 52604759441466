import { createSelector } from '@reduxjs/toolkit'

import { TAppSore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'queue'

const select = (state: TAppSore) => state[SLICE_NAME]

export const getQueueReducer = select

export const getCommunicationQueueSelector = createSelector(
  select,
  reducer => reducer.communication
)

export const getSanitizeQueueSelector = createSelector(
  select,
  reducer => reducer.sanitize
)
