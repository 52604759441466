export type TAuthReducer = {
  isLoggedIn: boolean
  loggedInAt?: string
  accessToken: string
  refreshToken: string
}

export const INITIAL_STATE: TAuthReducer = {
  isLoggedIn: false,
  accessToken: '',
  refreshToken: ''
}
