export type TLeadFilterObject = {
  label: string
  value: string
  count: number
}

export type TLeadFilterObjectMap = {
  [key: string]: TLeadFilterObject
}

export type TLeadFiltersReducer = {
  sicFilters: TLeadFilterObjectMap
  countryFilters: TLeadFilterObjectMap
  stateFilters: TLeadFilterObjectMap
  cityFilters: TLeadFilterObjectMap
}

export const INITIAL_STATE: TLeadFiltersReducer = {
  sicFilters: {},
  countryFilters: {},
  stateFilters: {},
  cityFilters: {}
}
