import { DsPalette, SupportedColorScheme } from '@am92/react-design-system'

export type TThemeReducer = {
  scheme: SupportedColorScheme
  palette: DsPalette
  fontFamily: string
}

export const DEFAULT_THEME: TThemeReducer = {
  scheme: 'dark',
  palette: {
    primaryBlack: '#000000',
    primaryBlackLight: '#222222',

    // Psycedelic Blue and Pink
    // primary: '#3433e0',
    // secondary100: '#f23de8',
    // secondary80: '#f23de8',
    // secondary60: '#f23de8',
    // secondary40: '#f23de8',
    // secondary20: '#f23de8'

    primary: '#1f51ff',
    secondary100: '#0ff0fc',
    secondary80: '#0ff0fc',
    secondary60: '#0ff0fc',
    secondary40: '#0ff0fc',
    secondary20: '#0ff0fc'

    // primary: '#1f68bf',
    // secondary100: '#ff3fa4',
    // secondary80: '#ff3fa4',
    // secondary60: '#ff3fa4',
    // secondary40: '#ff3fa4',
    // secondary20: '#ff3fa4'

    // primary: '#1f68bf',
    // secondary100: '#f23de8',
    // secondary80: '#f23de8',
    // secondary60: '#f23de8',
    // secondary40: '#f23de8',
    // secondary20: '#f23de8'
  },
  fontFamily: 'Montserrat'
}

export const INITIAL_STATE: TThemeReducer = DEFAULT_THEME
