import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'

import AuthLayoutLoader from '../Layouts/Auth.Layout/Auth.Layout.Loader'
import UnauthLayoutLoader from '../Layouts/Unauth.Layout/Unauth.Layout.Loader'
import AuthLayout from '~/src/Layouts/Auth.Layout'
import ErrorBoundary from '~/src/Layouts/ErrorBoundary'
import UnauthLayout from '~/src/Layouts/Unauth.Layout'

import NotFoundLoader from '../Pages/NotFound/NotFound.Loader'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

const LoginPage = React.lazy(() => import('~/src/Pages/Login/Login.Page'))

const DashboardPage = React.lazy(
  () => import('~/src/Pages/Dashboard/Dashboard.Page')
)

const CommunicationTemplateListPage = React.lazy(
  () =>
    import(
      '~/src/Pages/CommunicationTemplateList/CommunicationTemplateList.Page'
    )
)

const CommunicationTemplateFormPage = React.lazy(
  () =>
    import(
      '~/src/Pages/CommunicationTemplateForm/CommunicationTemplateForm.Page'
    )
)

const LeadDashboardPage = React.lazy(
  () => import('~/src/Pages/LeadDashboard/LeadDashboard.Page')
)

const LeadListPage = React.lazy(
  () => import('~/src/Pages/LeadList/LeadList.Page')
)

const LeadCommunicationPage = React.lazy(
  () => import('~/src/Pages/LeadCommunication/LeadCommunication.Page')
)

const LeadSanitizationPage = React.lazy(
  () => import('~/src/Pages/LeadSanitization/LeadSanitization.Page')
)

const LeadAnalyticsPage = React.lazy(
  () => import('~/src/Pages/LeadAnalytics/LeadAnalytics.Page')
)

const NotFoundPage = React.lazy(
  () => import('~/src/Pages/NotFound/NotFound.Page')
)

const routeObj: RouteObject[] = [
  {
    element: <UnauthLayout />,
    loader: UnauthLayoutLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.LOGIN.pathname,
        element: <LoginPage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    element: <AuthLayout />,
    loader: AuthLayoutLoader,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: APP_ROUTES.DASHBOARD.pathname,
        element: <DashboardPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMMUNICATION_TEMPLATE_LIST.pathname,
        element: <CommunicationTemplateListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.COMMUNICATION_TEMPLATE_FORM.pathname,
        element: <CommunicationTemplateFormPage />
      } as RouteObject,
      {
        path: APP_ROUTES.LEAD_DASHBOARD.pathname,
        element: <LeadDashboardPage />
      } as RouteObject,
      {
        path: APP_ROUTES.LEAD_LIST.pathname,
        element: <LeadListPage />
      } as RouteObject,
      {
        path: APP_ROUTES.LEAD_COMMUNICATION.pathname,
        element: <LeadCommunicationPage />
      } as RouteObject,
      {
        path: APP_ROUTES.LEAD_SANITIZATION.pathname,
        element: <LeadSanitizationPage />
      } as RouteObject,
      {
        path: APP_ROUTES.LEAD_ANALYTICS.pathname,
        element: <LeadAnalyticsPage />
      } as RouteObject
    ]
  } as RouteObject,

  {
    path: APP_ROUTES.ANY.pathname,
    loader: NotFoundLoader,
    errorElement: <ErrorBoundary />,
    element: <NotFoundPage />
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
