import traceActionsCreator from '../traceActionsCreator'

import { TGetCommunicationQueueStatusResponseData } from './ServiceTypes/getCommunicationQueueStatus.ServiceTypes'
import { TGetSanitizeQueueStatusResponseData } from './ServiceTypes/getSanitizeQueueStatus.ServiceTypes'
import { TStartProcessingCommunicationQueueResponseData } from './ServiceTypes/startProcessingCommunicationQueue.ServiceTypes'
import { TStartProcessingSanitizeQueueResponseData } from './ServiceTypes/startProcessingSanitizeQueue.ServiceTypes'
import { TStopProcessingCommunicationQueueResponseData } from './ServiceTypes/stopProcessingCommunicationQueue.ServiceTypes'
import { TStopProcessingSanitizeQueueResponseData } from './ServiceTypes/stopProcessingSanitizeQueue.ServiceTypes'

export const getCommunicationQueueStatusServiceName =
  'Queue/getCommunicationQueueStatus'
export const getCommunicationQueueStatusTraceActions =
  traceActionsCreator<TGetCommunicationQueueStatusResponseData>(
    getCommunicationQueueStatusServiceName
  )

export const startProcessingCommunicationQueueServiceName =
  'Queue/startProcessingCommunicationQueue'
export const startProcessingCommunicationQueueTraceActions =
  traceActionsCreator<TStartProcessingCommunicationQueueResponseData>(
    startProcessingCommunicationQueueServiceName
  )

export const stopProcessingCommunicationQueueServiceName =
  'Queue/stopProcessingCommunicationQueue'
export const stopProcessingCommunicationQueueTraceActions =
  traceActionsCreator<TStopProcessingCommunicationQueueResponseData>(
    stopProcessingCommunicationQueueServiceName
  )

export const getSanitizeQueueStatusServiceName = 'Queue/getSanitizeQueueStatus'
export const getSanitizeQueueStatusTraceActions =
  traceActionsCreator<TGetSanitizeQueueStatusResponseData>(
    getSanitizeQueueStatusServiceName
  )

export const startProcessingSanitizeQueueServiceName =
  'Queue/startProcessingSanitizeQueue'
export const startProcessingSanitizeQueueTraceActions =
  traceActionsCreator<TStartProcessingSanitizeQueueResponseData>(
    startProcessingSanitizeQueueServiceName
  )

export const stopProcessingSanitizeQueueServiceName =
  'Queue/stopProcessingSanitizeQueue'
export const stopProcessingSanitizeQueueTraceActions =
  traceActionsCreator<TStopProcessingSanitizeQueueResponseData>(
    stopProcessingSanitizeQueueServiceName
  )
