import { createSelector } from '@reduxjs/toolkit'

import { TAppSore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'communication'

const select = (state: TAppSore) => state[SLICE_NAME]

export const getCommunicationReducer = createSelector(
  select,
  reducer => reducer
)

export const getCommunicationsSelector = createSelector(
  select,
  reducer => reducer.communications
)

export const getCommunicationsSendMetricsSelector = createSelector(
  select,
  reducer => reducer.sendMetrics
)
