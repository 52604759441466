import { LEAD_STATUS } from '~/src/Entities/Lead/Lead.Constants'
import { TLead, TLeadStatus } from '~/src/Entities/Lead/Lead.Types'

export type TLeadSearchParams = Pick<
  TLead,
  'sicDescription' | 'country' | 'state' | 'city'
> & {
  page: number
  pageSize: number
  leadReachability: TLeadReachability
}

export type TLeadReachability =
  | 'COMMUNICATABLE'
  | 'NON_COMMUNICATABLE'
  | 'UNKNOW'
export type TLeadReachabilitysMap = { [key in TLeadReachability]: key }
export type TLeadReachabilityOption = {
  label: string
  value: TLeadReachability
}
export type TLeadReachabilityStatusMap = {
  [key in TLeadReachability]: TLeadStatus[]
}

export type TLeadSearchResult = {
  page: number
  pageSize: number
  totalCount: number
  docs: { [key: TLead['id']]: TLead }
}

export type TLeadReducer = {
  searchParams: TLeadSearchParams
  searchResult: TLeadSearchResult
}

export const LEAD_REACHABILITY: TLeadReachabilitysMap = {
  COMMUNICATABLE: 'COMMUNICATABLE',
  NON_COMMUNICATABLE: 'NON_COMMUNICATABLE',
  UNKNOW: 'UNKNOW'
}

export const INITIAL_STATE: TLeadReducer = {
  searchParams: {
    page: 0,
    pageSize: 10,
    sicDescription: '',
    country: '',
    state: '',
    city: '',
    leadReachability: LEAD_REACHABILITY.COMMUNICATABLE
  },
  searchResult: {
    page: 0,
    pageSize: 10,
    totalCount: 0,
    docs: {}
  }
}

export const LEAD_REACHABILITY_OPTIONS: TLeadReachabilityOption[] = [
  { label: 'Communicatable', value: LEAD_REACHABILITY.COMMUNICATABLE },
  { label: 'Non Communicatable', value: LEAD_REACHABILITY.NON_COMMUNICATABLE },
  { label: 'Unknow', value: LEAD_REACHABILITY.UNKNOW }
]

export const LEAD_REACHABILITY_STATUS_MAP: TLeadReachabilityStatusMap = {
  COMMUNICATABLE: [LEAD_STATUS.VALID],
  NON_COMMUNICATABLE: [
    LEAD_STATUS.HARD_BOUNCE,
    LEAD_STATUS.SOFT_BOUNCE,
    LEAD_STATUS.OPT_OUT
  ],
  UNKNOW: [LEAD_STATUS.UNKNOWN]
}
