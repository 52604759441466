import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  getCommunicationsTraceActions,
  getSendMetricsTraceActions,
  resetCommunicationReducerAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, TCommunicationReducer } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<TCommunicationReducer>
  ): void => {
    builder.addCase(
      getCommunicationsTraceActions.success,
      (state, { payload }) => {
        state.communications = payload
      }
    )

    builder.addCase(
      getSendMetricsTraceActions.success,
      (state, { payload }) => {
        state.sendMetrics = payload
      }
    )

    builder.addCase(resetCommunicationReducerAction, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
