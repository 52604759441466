import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  DsAvatar,
  DsBox,
  DsIconButton,
  DsMenu,
  DsMenuItem,
  DsRemixIcon,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import LoaderFullPage from '~/src/Components/LoaderFullPage'

import { getLoggedInAtSelector } from '~/src/Redux/Auth/Selectors'
import loginWithRefreshTokenService from '~/src/Redux/Auth/Services/loginWithRefreshToken.Service'
import logoutService, {
  logoutServiceName
} from '~/src/Redux/Auth/Services/logout.Service'
import { isServiceLoading } from '~/src/Redux/ServiceTracker/Selectors'
import { getUserReducer } from '~/src/Redux/User/Selectors'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { TAppDispatch, TAppSore } from '~/src/Configurations/AppStore'
import dayjs from '~/src/Utils/dayjs'

import { T_USER } from '~/src/Entities/User/User.Types'

interface IHeaderProps {
  isSubmitting: boolean
  loggedInAt: string
  user: T_USER
  actions: {
    logoutService: () => any
    loginWithRefreshTokenService: () => any
  }
}

interface IHeaderState {
  menuOpen: boolean
}

const DEFAULT_STATE: IHeaderState = {
  menuOpen: false
}

class Header extends Component<IHeaderProps & IWithRouterProps, IHeaderState> {
  state = DEFAULT_STATE
  menuRef: HTMLDivElement | null = null

  // TODO: Reference for Auto-Relogin
  componentDidMount(): void {
    const { loggedInAt } = this.props
    const loginDiff = dayjs(loggedInAt).diff(new Date(), 'minutes')

    if (isNaN(loginDiff) || loginDiff < -12) {
      this.handleRelogin()
    }
  }

  handleRelogin = async () => {
    const { actions } = this.props
    const response = await actions.loginWithRefreshTokenService()

    if (
      response?._isWebHttpError &&
      (response.errorCode === 'User::TOKEN_EXPIRED' ||
        response.errorCode === 'User::INVALID_REFRESH_TOKEN')
    ) {
      actions.logoutService()
    }
  }

  handleMenuOpen = () => this.setState({ menuOpen: true })

  handleMenuClose = () => this.setState({ menuOpen: false })

  handleLogoutMenuClick = async () => {
    const { actions } = this.props
    this.handleMenuClose()
    await actions.logoutService()
    this.props.navigateTo(APP_ROUTES.LOGIN.pathname)
  }

  render() {
    const { isSubmitting, user } = this.props
    const { menuOpen } = this.state

    return (
      <DsBox
        sx={theme => ({
          px: {
            xs: 'var(--ds-spacing-bitterCold)',
            lg: 'var(--ds-spacing-pleasant)'
          },
          py: 'var(--ds-spacing-cool)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--ds-colour-surfaceBackground)',
          position: 'sticky',
          top: 0,
          zIndex: theme.zIndex.appBar,
          borderBottom: '1px solid var(--ds-colour-strokeDefault)'
        })}
      >
        {isSubmitting && <LoaderFullPage />}

        <DsTypography variant='headingBoldExtraLarge'>Inque</DsTypography>

        <DsStack
          ref={ref => (this.menuRef = ref)}
          direction='row'
          spacing='var(--ds-spacing-quickFreeze)'
          sx={{
            p: 'var(--ds-spacing-frostbite)',
            border: '0.5px solid var(--ds-colour-strokeDefault)',
            borderRadius: '88px',
            alignItems: 'center'
          }}
        >
          <DsAvatar ds-variant='text' ds-size='S' sx={{ border: 'none' }}>
            {user.initials}
          </DsAvatar>
          <DsTypography variant='bodyRegularSmall'>
            {user.firstName}
          </DsTypography>
          <DsIconButton onClick={this.handleMenuOpen}>
            <DsRemixIcon className='ri-arrow-down-s-fill' />
          </DsIconButton>
          <DsMenu
            open={menuOpen}
            onClose={this.handleMenuClose}
            anchorEl={this.menuRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            PaperProps={{
              sx: {
                minWidth: '256px',
                backgroundImage: 'none',
                border: '0.5px solid var(--ds-colour-strokeDefault)'
              }
            }}
          >
            <DsMenuItem
              onClick={this.handleLogoutMenuClick}
              sx={{ color: 'var(--ds-colour-supportNegative)' }}
            >
              <DsRemixIcon
                className='ri-logout-circle-r-line'
                sx={{ mr: 'var(--ds-spacing-glacial)' }}
              />
              Logout
            </DsMenuItem>
          </DsMenu>
        </DsStack>
      </DsBox>
    )
  }
}

const mapStateToProps = (state: TAppSore) => {
  const isSubmitting = isServiceLoading(state, [logoutServiceName])
  const loggedInAt = getLoggedInAtSelector(state)
  const user = getUserReducer(state)

  return { isSubmitting, loggedInAt, user }
}

const mapDispatchToProps = (dispatch: TAppDispatch) => ({
  actions: {
    logoutService: () => dispatch(logoutService()),
    loginWithRefreshTokenService: () => dispatch(loginWithRefreshTokenService())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header))
