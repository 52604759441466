import traceActionsCreator from '../traceActionsCreator'

import { ILoginResponseData } from './ServiceTypes/login.ServiceTypes'
import { ILoginWithRefreshTokenResponseData } from './ServiceTypes/loginWithRefreshToken.ServiceTypes'

export const loginWithRefreshTokenServiceName = 'auth/loginWithRefreshToken'
export const loginWithRefreshTokenTraceActions =
  traceActionsCreator<ILoginWithRefreshTokenResponseData>(
    loginWithRefreshTokenServiceName
  )

export const loginServiceName = 'auth/login'
export const loginTraceActions =
  traceActionsCreator<ILoginResponseData>(loginServiceName)

export const logoutServiceName = 'auth/logout'
export const logoutTraceActions = traceActionsCreator(logoutServiceName)
