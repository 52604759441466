import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  findCommunicationTemplateByIdTraceActions,
  getCommunicationTemplatesByStatusTraceActions,
  listCommunicationTemplatesTraceActions,
  resetCommunicationTemplateReducerAction,
  resetSelectedCommunicationTemplateAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, TCommunicationTemplateReducer } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<TCommunicationTemplateReducer>
  ): void => {
    builder.addCase(
      listCommunicationTemplatesTraceActions.success,
      (state, { payload }) => {
        state.communicationTemplates = payload
      }
    )

    builder.addCase(
      findCommunicationTemplateByIdTraceActions.success,
      (state, { payload }) => {
        state.selectedCommunicationTemplate = payload
      }
    )

    builder.addCase(resetSelectedCommunicationTemplateAction, state => {
      state.selectedCommunicationTemplate = undefined
    })

    builder.addCase(resetCommunicationTemplateReducerAction, () => ({
      ...INITIAL_STATE
    }))

    builder.addCase(
      getCommunicationTemplatesByStatusTraceActions.success,
      (state, { payload }) => {
        state.communicationTemplates = payload
      }
    )
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
