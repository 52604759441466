import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  loginTraceActions,
  loginWithRefreshTokenTraceActions,
  logoutTraceActions
} from '../Auth/Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, TUserReducer } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TUserReducer>): void => {
    builder.addCase(loginTraceActions.success, (state, { payload }) => {
      const { tokens, ...user } = payload
      const { firstName } = user
      user.initials = firstName[0].toUpperCase()
      return user
    })

    builder.addCase(logoutTraceActions.success, () => ({
      ...INITIAL_STATE
    }))

    builder.addCase(logoutTraceActions.error, () => ({
      ...INITIAL_STATE
    }))

    builder.addCase(loginWithRefreshTokenTraceActions.error, () => ({
      ...INITIAL_STATE
    }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
