// import { createAction } from '@reduxjs/toolkit'
import traceActionsCreator from '../traceActionsCreator'
import { TLeadFilterObject } from './TYPES'

// export const sampleAction = createAction('LeadFilters/sample')

export const getSicFiltersServiceName = 'LeadFilters/getSicFilters'
export const getSicFiltersTraceActions = traceActionsCreator<
  TLeadFilterObject[]
>(getSicFiltersServiceName)

export const getCountryFiltersServiceName = 'LeadFilters/getCountryFilters'
export const getCountryFiltersTraceActions = traceActionsCreator<
  TLeadFilterObject[]
>(getCountryFiltersServiceName)

export const getStateFiltersServiceName = 'LeadFilters/getStateFilters'
export const getStateFiltersTraceActions = traceActionsCreator<
  TLeadFilterObject[]
>(getStateFiltersServiceName)

export const getCityFiltersServiceName = 'LeadFilters/getCityFilters'
export const getCityFiltersTraceActions = traceActionsCreator<
  TLeadFilterObject[]
>(getCityFiltersServiceName)
