import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  getCommunicationQueueStatusTraceActions,
  getSanitizeQueueStatusTraceActions,
  startProcessingCommunicationQueueTraceActions,
  startProcessingSanitizeQueueTraceActions,
  stopProcessingCommunicationQueueTraceActions,
  stopProcessingSanitizeQueueTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, TQueueReducer } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TQueueReducer>): void => {
    builder.addCase(
      getCommunicationQueueStatusTraceActions.success,
      (state, { payload }) => {
        state.communication = payload
      }
    )

    builder.addCase(
      startProcessingCommunicationQueueTraceActions.success,
      (state, { payload }) => {
        state.communication = payload
      }
    )

    builder.addCase(
      stopProcessingCommunicationQueueTraceActions.success,
      (state, { payload }) => {
        state.communication = payload
      }
    )

    builder.addCase(
      getSanitizeQueueStatusTraceActions.success,
      (state, { payload }) => {
        state.sanitize = payload
      }
    )

    builder.addCase(
      startProcessingSanitizeQueueTraceActions.success,
      (state, { payload }) => {
        state.sanitize = payload
      }
    )

    builder.addCase(
      stopProcessingSanitizeQueueTraceActions.success,
      (state, { payload }) => {
        state.sanitize = payload
      }
    )
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
