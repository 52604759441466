import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import { resetLeadReducerAction } from '../Lead/Actions'
import {
  getCityFiltersTraceActions,
  getCountryFiltersTraceActions,
  getSicFiltersTraceActions,
  getStateFiltersTraceActions
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, TLeadFiltersReducer } from './TYPES'

import { _keyBy } from '~/src/Utils/lodash'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<TLeadFiltersReducer>
  ): void => {
    builder.addCase(getSicFiltersTraceActions.success, (state, { payload }) => {
      state.sicFilters = _keyBy(payload, 'value')
    })

    builder.addCase(
      getCountryFiltersTraceActions.success,
      (state, { payload }) => {
        state.countryFilters = _keyBy(payload, 'value')
      }
    )

    builder.addCase(
      getStateFiltersTraceActions.success,
      (state, { payload }) => {
        state.stateFilters = _keyBy(payload, 'value')
      }
    )

    builder.addCase(
      getCityFiltersTraceActions.success,
      (state, { payload }) => {
        state.cityFilters = _keyBy(payload, 'value')
      }
    )

    builder.addCase(resetLeadReducerAction, () => ({ ...INITIAL_STATE }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
