import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import {
  resetLeadReducerAction,
  resetSearchParamsAction,
  searchLeadTraceActions,
  setSearchParamsAction
} from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, TLeadReducer } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder: ActionReducerMapBuilder<TLeadReducer>): void => {
    builder.addCase(setSearchParamsAction, (state, { payload }) => {
      state.searchParams = payload
    })

    builder.addCase(resetSearchParamsAction, state => {
      state.searchParams = { ...INITIAL_STATE.searchParams }
    })

    builder.addCase(searchLeadTraceActions.success, (state, { payload }) => {
      state.searchResult = payload
    })

    builder.addCase(resetLeadReducerAction, () => ({ ...INITIAL_STATE }))
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
